* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#main {
    display: flex;
    padding: 20px;
    width: 100vw;
    height: 95vh;
    overflow: hidden;
}

#pageContainer {
    width: 100%;
    position: relative;
    padding: 2rem 4rem 2rem 4rem;
    text-align: center;
}

#innerContainer {
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
    align-items: center;
}

#brandContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

#brandLogo {
    width: 125px;
    margin: -2rem auto 1rem;
}

#factButton {
    width: 100%;
    border: none;
    /*border-radius: 15px;*/
    -webkit-filter: drop-shadow(0 10px 0 #217d9d);
    filter: drop-shadow(0 10px 0 #217d9d);
    transition: opacity 1s;
}

#factButton:hover {
    /*background-color: #3e8e41*/
}


#factButton:active {
    -webkit-filter: drop-shadow(0 6px 0 #217d9d);
    filter: drop-shadow(0 6px 0 #217d9d);
    transform: translateY(4px);
}

#factInput {
    text-align: center;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    transition: opacity 1s;
}

#factInput:focus {
    outline: none;
}

hr {
    width: 80vw;
    transition: opacity 1s;
}

.fade {
    opacity: 0;
    pointer-events: none;
}

#dividerTitle {
    margin: -2.5rem auto 1rem;
    display: table;
    padding: 0 1rem;
    z-index: 2;
    background-color: #eee;
}

#footer {
    position: absolute;
    text-align: center;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
}

/*Extra small devices (portrait phones, less than 576px)*/
/* No media query for `xs` since this is the default in Bootstrap*/

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
    #factButton {
        width: 50%;
    }
    #brandLogo {
        width: 125px;
    }
    #pageContainer {
        font-size: 1rem;
    }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    #brandLogo {
        width: 25vw;
    }
    #pageContainer {
        padding: 2rem 8rem;
        font-size: 2rem;
    }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    #brandLogo {
        width: 15vw;
    }
    #pageContainer {
        padding: 4rem 8rem 12rem;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) { }