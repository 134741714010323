#fact {
    text-align: center;
    font-family: "Roboto", sans-serif;
}

#factImage {
    max-width: 300px;
    max-height: 300px;
    transition: opacity 1s;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
}

#factImage:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

#factImage .fade {
    opacity: 0;
}

/*Extra small devices (portrait phones, less than 576px)*/
/* No media query for `xs` since this is the default in Bootstrap*/

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
    .fact {
        font-size: 15px;
    }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .fact {
        font-size: 20px;
    }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .fact {
        font-size: 30px;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {  }